import {
  View, Text, Image, TouchableOpacity,
} from 'react-native';

import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import inputStyles from '../styles/input';
import cardStyle from '../styles/card';
import VotedByList from './VotedByList';
import avatars from '../scripts/avatars';
import Crown from './Crown';
import { Player } from '../interfaces/player';
import { Game } from '../interfaces/game';

interface Props {
  won: boolean,
  ownPlayer: Player,
  player: Player,
  game: Game,
  socket: WebSocket,
}

class PlayerCard extends Component<Props> {
  getCardStyle() {
    if (this.props.won) {
      return cardStyle.electedCard;
    }
    if (this.props.ownPlayer && this.props.player.voted_by.includes(this.props.ownPlayer.pk)) {
      return cardStyle.votedCard;
    }
    return cardStyle.playerCard;
  }

  vote = (player: Player) => {
    this.props.socket?.send(JSON.stringify({
      vote: player.pk,
    }));
  }

  kick = (player: Player) => {
    if (window.confirm(`Are you sure you want to kick ${player.name}?`)) {
      this.props.socket?.send(JSON.stringify({
        kick: player.pk,
      }));
    }
  }

  kickComponent() {
    if (!this.props.ownPlayer.is_host || this.props.player.pk === this.props.ownPlayer.pk) {
      return null;
    }

    return (
      <TouchableOpacity
        key={this.props.player.pk}
        onPress={() => this.kick(this.props.player)}
      >
        <FontAwesomeIcon icon={faTimes} color="#FF0000" />
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <TouchableOpacity
        key={this.props.player.pk}
        style={this.getCardStyle()}
        onPress={() => this.vote(this.props.player)}
      >
        <Image
          source={avatars.intToAvatar(this.props.player.profile_picture)}
          style={{
            width: 50, height: 50, borderRadius: 50, marginRight: 5,
          }}
        />
        <Crown
          won={this.props.won}
        />
        <View style={{ minWidth: 150 }}>
          <Text
            style={[inputStyles.primaryBtnText, {
              textTransform: 'capitalize',
            }]}
          >
            {this.props.player.name}
          </Text>
          <VotedByList
            player={this.props.player}
            game={this.props.game}
          />
        </View>

        {this.kickComponent()}
      </TouchableOpacity>
    );
  }
}

export default PlayerCard;
