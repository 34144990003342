import { StyleSheet, View, Text } from 'react-native';
import React, { Component } from 'react';

import { Link } from 'react-router-native';
import { StatusBar } from 'expo-status-bar';
import { Game } from '../interfaces/game';
import { Player } from '../interfaces/player';
import api from '../scripts/api';
import input from '../styles/input';

const styles = StyleSheet.create({
  title: {
    color: 'white',
    fontSize: 50,
    paddingVertical: 50,
  },
});

interface GetGameResponse {
  game: Game,
  player: Player
}

interface Props {
  history: any,
}

class Home extends Component<Props> {
  constructor(props: any) {
    super(props);

    this.promptReconnect();
  }

  rejoin() {
    api.get('games/ongoing_session').then((response: GetGameResponse) => this.props.history.push({
      pathname: '/game',
      state: {
        game: response.game,
        player: response.player,
      },
    })).catch();
  }

  promptReconnect() {
    if (api.hasSession()) {
      // TODO Check if window.confirm works on Android.
      if (window.confirm('Do you wish to jump back into the game?')) {
        this.rejoin();
      } else {
        api.quitSession();
      }
    }
  }

  // checkAndRedirectJoin() {
  //   // Check if url is join url.
  //   const path = this.props.location.pathname;
  //   if (path.includes('join')) {
  //     const code = path.split('/')[2];

  //     cookie.save('join', code, { path: '/' });

  //     this.props.location.pathname = '';
  //   }

  //   if (cookie.load('join')) {
  //     this.props.history.push({
  //       pathname: '/join',
  //     });
  //   }
  // }

  render() {
    return (
      <View>
        <Link
          style={input.primaryBtn}
          to="/join"
        >
          <Text style={input.primaryBtnText}>Join</Text>
        </Link>
        <Text style={styles.title}>Who is..?</Text>
        <Link
          style={input.primaryBtn}
          to="/create"
        >
          <Text style={input.primaryBtnText}>Create</Text>
        </Link>
        <StatusBar />
      </View>
    );
  }
}

export default Home;
