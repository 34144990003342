import React, { Component } from 'react';
import {
  View, Text, TouchableOpacity, TextInput, ViewStyle,
} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faBan } from '@fortawesome/free-solid-svg-icons';
import inputStyles from '../styles/input';
import api from '../scripts/api';
import { Game, Phase } from '../interfaces/game';
import { Player } from '../interfaces/player';
import main from '../styles/main';
import KoFi from './Kofi';

interface Props {
  socket: WebSocket,
  player: Player,
  game: Game,
}

class Controls extends Component<Props> {
  question: string

  constructor(props: any) {
    super(props);
    this.question = '';
  }

  setQuestion = (question: string) => {
    this.question = question;
  }

  postQuestion = () => {
    const { textInput } = this;
    if (textInput.value === '') {
      // eslint-disable-next-line no-undef
      toast.show('No question typed.');
      return;
    }
    api.post('questions', {
      question: this.question,
    })
      .then(() => {
        // Clear the text input to show the question is send.
        textInput.clear();
        // eslint-disable-next-line no-undef
        toast.show('Added question.', { type: 'success' });
      }).catch(() => { });
  }

  skip = () => {
    this.props.socket?.send(JSON.stringify({
      skip: true,
    }));
  }

  next = () => {
    this.props.socket?.send(JSON.stringify({
      next: true,
    }));
  }

  showResults() {
    if (!this.props.player.is_host || this.props.game.phase === Phase.ANSWER) {
      return null;
    }

    return (
      <TouchableOpacity
        style={inputStyles.primaryBtn}
        onPress={() => this.next()}
      >
        <Text
          style={inputStyles.primaryBtnText}
        >
          Show Results
        </Text>
        <FontAwesomeIcon icon={faArrowRight} />
      </TouchableOpacity>
    );
  }

  skipQuestion() {
    if (!this.props.player.is_host || this.props.game.phase === Phase.ANSWER) {
      return null;
    }

    return (
      <TouchableOpacity
        style={inputStyles.primaryBtn}
        onPress={() => this.skip()}
      >
        <Text
          style={inputStyles.primaryBtnText}
        >
          Skip Question
        </Text>
        <FontAwesomeIcon icon={faBan} />
      </TouchableOpacity>
    );
  }

  copy() {
    Clipboard.setString(this.props.game?.code);
    // eslint-disable-next-line no-undef
    toast.show('Copied to clipboard');
  }

  render() {
    const controlsStyle: ViewStyle = {
      marginTop: 100,
    };
    return (
      <View>
        <View style={[main.row, main.center, main.mb1, controlsStyle]}>
          <TouchableOpacity
            style={inputStyles.primaryBtn}
            onPress={() => this.copy()}
          >
            <Text style={inputStyles.primaryBtnText}>
              {this.props.game?.code}
            </Text>
            <FontAwesomeIcon icon={faCopy} />
          </TouchableOpacity>
          {this.skipQuestion()}
          {this.showResults()}
        </View>
        <View style={[main.row, main.center]}>
          <TextInput
            style={inputStyles.primaryInput}
            placeholder="Add your own question!"
            placeholderTextColor="#D1C4E9"
            autoCapitalize="sentences"
            onChangeText={this.setQuestion}
            ref={(input) => { this.textInput = input; }}
            onSubmitEditing={this.postQuestion}
          />
          <TouchableOpacity
            onPress={this.postQuestion}
            style={inputStyles.primaryBtn}
          >
            <Text style={inputStyles.primaryBtnText}>Add question</Text>
          </TouchableOpacity>
        </View>
        <KoFi />
      </View>
    );
  }
}

export default Controls;
